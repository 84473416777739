import { compose, Fields, hako } from '@hako/core'
import { Layout } from '@/components/Layout'
import { fetchRelatedProducts } from '@/modules/products/ssr/fetchRelatedProducts'
import Link from 'next/link'
import React from 'react'

export default function Home({ relatedProducts }) {
    return (
        <Layout>
            <section className="section section--video">
                <div
                    style={{
                        padding: '56.25% 0 0 0',
                        position: 'relative',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <iframe
                        src="https://player.vimeo.com/video/852938600?h=2678923cd0&badge=0&autopause=0&player_id=0&app_id=58479"
                        title="Philips Evnia brand film"
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                        }}
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </section>

            <section className="section section--gray people grid">
                <div className="grid-column-start-3 grid-column-end-6 grid-column-tablet-start-1 grid-column-tablet-end-5 grid-column-mobile-start-1 grid-column-mobile-end-5">
                    <Fields.Text
                        as="h2"
                        slug="homepage-block-1-header"
                        className="h2"
                    >
                        {"It's not about being the best"}
                    </Fields.Text>
                    <Fields.Text
                        as="p"
                        className="paragraph paragraph--big"
                        slug="homepage-block-1-text"
                    >
                        It is time for you. In a different world but on your
                        terms.
                    </Fields.Text>

                    <Link href="/manifesto">
                        <Fields.Text
                            as="span"
                            className="btn btn--tertiary btn--full-mobile u-margin-top-small"
                            slug="homepage-block-1-manifesto-button"
                        >
                            See our manifesto
                        </Fields.Text>
                    </Link>
                </div>
                <Fields.Background
                    slug="people-section-image"
                    src="/images/a70051e64990d02186f6.webp"
                    className="grid-column-start-7 grid-column-end-13 grid-column-tablet-start-5 grid-column-tablet-end-9 grid-column-mobile-start-1 grid-column-mobile-end-5 people-image people-image--2"
                ></Fields.Background>
            </section>

            <section className="section grid u-padding-bottom-xxlarge">
                <div className="grid-container">
                    <Fields.Text
                        as="h2"
                        className="h3 u-margin-bottom-medium"
                        slug="homepage-block-2-header"
                    >
                        Enjoy our solution
                    </Fields.Text>

                    <div className="row homepage-box--container">
                        {relatedProducts.map((product) => (
                            <div
                                key={product.id}
                                className="box u-margin-top-small homepage-box"
                            >
                                <div>
                                    <img
                                        src={product?.image?.url}
                                        alt={product?.image?.alt}
                                        width="716"
                                        height="416"
                                    />
                                </div>
                                <div className="box__footer">
                                    <div>
                                        <span className="product-name">
                                            {product.name}
                                        </span>
                                        <h3 className="h4">
                                            {product.description}
                                        </h3>
                                    </div>
                                    <Link href={`/products/${product.slug}`}>
                                        <button className="btn btn--primary btn--light btn--full">
                                            <Fields.Text
                                                as="span"
                                                scope="global"
                                                slug="show-product-button"
                                            >
                                                See the monitor
                                            </Fields.Text>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Fields.Background
                slug="section-section--image"
                src="/images/section2.webp"
                className="section section--image"
                as="section"
            ></Fields.Background>

            <section className="section section--gray grid u-padding-top-xlarge u-padding-bottom-xlarge">
                <div
                    className="grid-container"
                    style={{ position: 'relative' }}
                >
                    <div className="circle-shadow circle-shadow--light"></div>
                    <p className="h2 highlight-text--block u-padding-top-medium u-padding-bottom-medium">
                        <Fields.Text
                            as="span"
                            className="u-padding-bottom-medium"
                            slug="homepage-bottom-line-1"
                        >
                            Get immersed
                        </Fields.Text>
                        <br />
                        <Fields.Text
                            as="span"
                            className="u-padding-bottom-medium"
                            slug="homepage-bottom-line-2"
                        >
                            Perfect for you, every you
                        </Fields.Text>
                        <br />
                        <Fields.Text
                            as="span"
                            className="u-padding-bottom-medium"
                            slug="homepage-bottom-line-3"
                        >
                            Play at your best and at your pace
                        </Fields.Text>
                        <br />
                        <Fields.Text
                            as="span"
                            className="u-padding-bottom-medium highlight-text"
                            slug="homepage-bottom-line-4"
                        >
                            Play at your best and at your pace
                        </Fields.Text>
                        <br />
                        <Fields.Text
                            as="span"
                            className="u-padding-bottom-medium highlight-text"
                            slug="homepage-bottom-line-5"
                        >
                            Play at your best and at your pace
                        </Fields.Text>
                        <br />
                        <Fields.Text
                            as="span"
                            className="u-padding-bottom-medium highlight-text"
                            slug="homepage-bottom-line-6"
                        >
                            Play at your best and at your pace
                        </Fields.Text>
                        <br />
                    </p>
                </div>
            </section>
        </Layout>
    )
}

export const getServerSideProps = compose(
    hako('page', null, ''),
    fetchRelatedProducts
)
